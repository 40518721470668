 /* globals  */

  :root {
    --lime-green: hsl(163, 72%, 41%);
    --bright-red: hsl(356, 69%, 56%);
    --facebook: #168ef4;
    --twitter: hsl(203, 89%, 53%);
    --instagram-1: hsl(37, 97%, 70%);
    --instagram-2: hsl(329, 70%, 58%);
    --youTube: hsl(348, 97%, 39%);
    --gray: #5f616d;
    --cardGray: #f0f3fa;
    --lightGray: #f8f9fe;
    --cardTitle: #6f717e;
    --baseBackground: white;
    --baseColor: #1d2029;
    --switchSpinner: white;
    --switch: #5f616d;
  }
  .wrapper {
    padding: 0 30px;
  }
  .grid {
    display: grid;
    grid-row-gap: 25px;
  }
  .dark-mode {
    display: flex;
    justify-content: space-between;
    padding-top: 1em;
  }
  .checkbox {
    display: none;
  }
  .checkbox:checked ~ .switch:before{
    transform: translateX(0);
  }
  .switch {
    /* border: 1px solid red; */
    height: 40px;
    display: block;
    border-radius: 20px;
    background: var(--switch);
    width: 80px;
    padding: 5px;
    box-sizing: border-box;
    cursor: pointer;
    user-select: none;
  }
  .switch::before {
    content: "";
    display: block;
    width: 30px;
    height: 30px;
    background: var(--switchSpinner);
    border-radius: 50%;
    transform: translateX(40px);
    transition: .3s;
    will-change: transform;
  }
  /* header  */
  .header {
    /* border: 1px solid red; */
    background: var(--lightGray);
    padding-bottom: 100px;
    border-radius: 0 0 20px 20px;
    padding-top: 2em;
  }
  .header-total {
    color: var(--gray);
    margin: .5em 0;
    font-weight: 600;
    border-bottom: 1px solid var(--gray);
    padding-bottom: 1.5em;
  }
  h1 {
    margin: 0;
    font-size: 1.7em;
  }
  /* top card  */
  .top-cards {
    /* border: 1px solid green; */
    margin-top: -50px;
    margin-bottom: 3em;
  }
  
  
  @media screen and (min-width: 480px) {
    .grid {
      /* border: 1px solid purple; */
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 25px;
    }
    .wrapper {
      max-width: 1440px;
      margin: auto;
    }
  }
  
  @media screen and (min-width: 768px) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }
  
  @media screen and (min-width: 1024px) {
    .grid {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    .header-total {
      border: none;
    }
    .header-grid {
      display: grid;
      grid-template-columns: 1fr 200px;
    }
  }
  
  .is-dark-mode {
    --cardGray: #252b43;
    --baseBackground: #1d2029;
    --baseColor: white;
    --gray: #8088ad;
    --lightGray: #1d2029;
    --cardTitle: #8088ad;
    --switchSpinner: #282943;
    --switch: linear-gradient(to right, #4796d2 0%, #37ce8f 100%);
  }
  
  .is-light-mode {
    --gray: #5f616d;
    --cardGray: #f0f3fa;
    --lightGray: #f8f9fe;
    --cardTitle: #6f717e;
    --baseBackground: white;
    --baseColor: #1d2029;
    --switchSpinner: white;
    --switch: #5f616d;
  }
  
  @media (prefers-color-scheme: dark) {
    :root {
      --cardGray: #252b43;
      --baseBackground: #1d2029;
      --baseColor: white;
      --gray: #8088ad;
      --lightGray: #1d2029;
      --cardTitle: #8088ad;
      --switchSpinner: #282943;
      --switch: linear-gradient(to right, #4796d2 0%, #37ce8f 100%);
    }
  }